import { useState, useEffect, useRef, useCallback } from "react";

const useWebSocket = (url) => {
  //  const [isConnected, setIsConnected] = useState(false);
  const isConnected = useRef(false);
  const webSocketRef = useRef(null);
  const listenersRef = useRef(new Set());
  const isMountedRef = useRef(true);
  const messageQueue = useRef([]); // Ref to hold the message queue
  const intervalRef = useRef(null); // Ref to hold the interval ID

  const close = useCallback(() => {
    if (webSocketRef.current) {
      webSocketRef.current.close(1000, "Closing connection");
      setTimeout(() => {
        if (
          webSocketRef.current &&
          webSocketRef.current.readyState !== WebSocket.CLOSED
        ) {
          console.log("Forcing WebSocket close after timeout...");
          webSocketRef.current.close(); // Force close
        }
      }, 300); // 0.5 second timeout

      if (isMountedRef.current) {
        //setIsConnected(false);
        isConnected.current = false;
      }
    }
  }, []);

  const log = useCallback((message) => {
    console.log(`WebSocket: ${message}`);
  }, []);

  const notifyListeners = useCallback((event, data) => {
    listenersRef.current.forEach((listener) => {
      if (listener[event]) {
        listener[event](data);
      }
    });
  }, []);
  let lastDequeueTime = Date.now(); // Initialize the last dequeue timestamp
  useEffect(() => {
    intervalRef.current = setInterval(() => {
      if (messageQueue.current.length > 0) {
        const currentTime = Date.now();
        const timeSinceLastDequeue = currentTime - lastDequeueTime; // Calculate interval since last dequeue

        const nextMessage = messageQueue.current.shift(); // Get the next message from the queue
        processBufferedMessage(nextMessage);

        const queueCount = messageQueue.current.length; // Get the queue count after dequeue

        // Log the interval and queue count in one line
        // console.log(
        //   `Interval since last dequeue: ${timeSinceLastDequeue} ms, Queue count: ${queueCount}`
        // );

        lastDequeueTime = currentTime; // Update the last dequeue timestamp
      }
    }, 20); // 120 ms interval
    return () => {
      clearInterval(intervalRef.current); // Clear the interval on unmount
    };
  }, []);
  // useEffect(() => {
  //   intervalRef.current = setInterval(() => {
  //     if (messageQueue.current.length > 0) {
  //       const nextMessage = messageQueue.current.shift(); // Process the next message
  //       if (nextMessage === "0200025300") {
  //       } else {
  //         processBufferedMessage(nextMessage);
  //       }

  //       if (nextMessage === "0200025200") {
  //         setTimeout(() => {
  //           console.log("TEST--------------");
  //           processBufferedMessage("0200025300");
  //         }, 500);
  //       }
  //     }
  //   }, 20);

  //   return () => {
  //     clearInterval(intervalRef.current); // Clear the interval on unmount
  //   };
  // }, []);
  const processBufferedMessage = (messageStr) => {
    const cmd = messageStr.substring(6, 8).toUpperCase();
    switch (cmd) {
      case "50":
        notifyListeners("onStartSessionAck");
        break;
      case "52":
        notifyListeners("onStartAck");
        break;
      case "53":
        notifyListeners("onEndAck");
        break;
      case "54":
        notifyListeners("onEndSessionAck");
        break;
      default:
        log(`Unknown ackCommand: ${cmd}`);
        break;
    }
  };

  const handleMessage = useCallback(
    (messageStr) => {
      const commandCode = messageStr.substring(0, 2);
      switch (commandCode) {
        case "REJECT":
          notifyListeners("onReject", "-EMPTY-");
          break;
        case "01":
          //console.log("01");
          notifyListeners("onAudioData", messageStr);
          break;
        case "02":
          console.log(
            "AULOG RECEIVE: " + messageStr + " " + new Date().getTime()
          );
          messageQueue.current.push(messageStr); // Add message to the queue
          break;
        default:
          log(`Unknown command: ${commandCode}`);
          break;
      }
    },
    [notifyListeners, log]
  );

  const connect = useCallback(() => {
    if (webSocketRef.current) {
      webSocketRef.current.close();
    }

    webSocketRef.current = new WebSocket(url);

    const connectionTimeout = setTimeout(() => {
      if (
        webSocketRef.current &&
        webSocketRef.current.readyState !== WebSocket.OPEN
      ) {
        log("Connection timed out");
        webSocketRef.current.close();
        if (isMountedRef.current) {
          //setIsConnected(false);
          isConnected.current = false;
          notifyListeners("connection", "timeout");
        }
      }
    }, 2000); // 2 seconds timeout

    webSocketRef.current.onopen = () => {
      clearTimeout(connectionTimeout);
      if (isMountedRef.current) {
        //setIsConnected(true);
        isConnected.current = true;
        notifyListeners("connection", "connected");
      }
    };

    webSocketRef.current.onclose = () => {
      clearTimeout(connectionTimeout);
      log("Disconnected");
      if (isMountedRef.current) {
        // setIsConnected(false);
        isConnected.current = false;
        notifyListeners("connection", "disconnected");
      }
    };

    webSocketRef.current.onerror = (error) => {
      clearTimeout(connectionTimeout);
      log(`Error: ${error.message}`);
      if (isMountedRef.current) {
        notifyListeners("connection", `error: ${error.message}`);
      }
    };

    webSocketRef.current.onmessage = (e) => {
      const messageStr = e.data;
      const reader = new FileReader();
      reader.onload = () => {
        const arrayBuffer = reader.result;
        const bytes = new Uint8Array(arrayBuffer);
        let messageStr = "";
        bytes.forEach((byte) => {
          messageStr += byte.toString(16).padStart(2, "0");
        });
        handleMessage(messageStr);
      };

      reader.onerror = (error) => log(`Error reading blob: ${error}`);
      reader.readAsArrayBuffer(e.data);
    };
  }, [url, log, notifyListeners, handleMessage]);

  useEffect(() => {
    return () => {
      isMountedRef.current = false; // Mark the component as unmounted
      if (webSocketRef.current) {
        webSocketRef.current.close();
      }
    };
  }, []);

  const hexToByteArray = (hexString) => {
    var bytes = new Uint8Array(Math.ceil(hexString.length / 2));
    for (var i = 0, j = 0; i < hexString.length; i += 2, j++) {
      bytes[j] = parseInt(hexString.substr(i, 2), 16);
    }
    return bytes;
  };

  const sendMessage = useCallback(
    (message) => {
      if (
        webSocketRef.current &&
        webSocketRef.current.readyState === WebSocket.OPEN &&
        isConnected.current
      ) {
        if (!message.startsWith("51")) {
          console.log("AULOG SEND: " + message);
        } else {
          console.log("51");
        }
        const messageBytes = hexToByteArray(message);
        webSocketRef.current.send(messageBytes);
      } else {
        // console.log(
        //   "WebSocket is not open or not connected. Message not sent: " + message
        // );
      }
    },
    [isConnected, log]
  );

  const addListener = useCallback((listener) => {
    listenersRef.current.add(listener);
  }, []);

  const removeListener = useCallback((listener) => {
    listenersRef.current.delete(listener);
  }, []);

  return {
    connect,
    sendMessage,
    addListener,
    removeListener,
    isConnected,
    close,
  };
};

export default useWebSocket;
