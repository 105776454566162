import React, { useState, useEffect, useCallback, useRef } from "react";
import cx from "classnames";
import "./NurseCall.css";

import BreathImg from "../../../images/oac/RESP-1A-R1.png";
import HeartImg from "../../../images/oac/PR-R1.png";
import SpinnerImg from "../../../images/oac/icon_loading_2.gif";
import UpdatingImg from "../../../images/oac/icon_loading_2.gif";

import SleepingImg from "../../../images/oac/OAC_Group_1313.png";
import AwakeImg from "../../../images/oac/OAC_Group_1243.png";
import AbsentImg from "../../../images/oac/OAC_Pillow-6D-Awake-faf36b_png_2.png";
import EOLImg from "../../../images/oac/OAC_selection.png";
// import BCUDisconnect from "../../../images/oac/bcu-disconnect.png";
// import NurseCallButton from "../../../images/oac/touch.png";
import MicButton from "../../../images/oac/micro_orange.gif";
import MicButtonOff from "../../../images/oac/ic_voice_grey.png";
import Loading from "../../../images/oac/icon_loading_hq.gif";

import CloseButton from "../../../images/oac/OAC_Plain_SVG_5.png";

import volumeBar from "../../../images/oac/ic_volumn_bar_grey.png";
import greySpeaker from "../../../images/oac/ic_speaker_grey.png";
import greenSpeaker from "../../../images/oac/ic_speaker_green.png";
import useWebSocket from "./useWebSocket";
import { JitterBuffer, AudioPacket } from "./jitter";
import CommandCreator from "./command";
import { Decoder, Encoder } from "libopus.js";
import { messageWrapper as t } from "../../../utils/message-wrapper";

import $ from "jquery";
import Marquee from "react-fast-marquee";

import IntercomBlack from "../../../images/intercom_black.png";
import IntercomRed from "../../../images/intercom_red.png";

import {
  hexToInt,
  hexToByteArray,
  reverseHex,
  parseMessageInBytes,
} from "./util_parser";
import { IrespModal } from "../../common/IrespModal";

export function NurseCall(props) {
  const lockRef = useRef(false); // useRef for managing the lock
  const lockTimeoutRef = useRef(null); // useRef to store the timeout ID

  const { info } = props;
  const [state, setState] = useState({ needMarquee: false });
  const [buttonImage, setButtonImage] = useState(Loading);
  const [loaded, setLoaded] = useState(false); // New state to track if initial load is done

  const [ipAddress, setIpAddress] = useState(info.ip);
  const [port, setPort] = useState("80");
  const wsUrl = `ws://${ipAddress}:${port}/oacaudio`;
  // const [isSessionOpen, setIsSessionOpen] = useState(false);
  const isSessionOpen = useRef(false);
  const jitterBufferRef = useRef(new JitterBuffer(2000, 0));
  const pollIntervalRef = useRef(null); // For storing the interval ID
  // const [isRecording, setIsRecording] = useState(false);
  const recordingRef = useRef(false);
  const audioContextRef = useRef(null);
  const decoderRef = useRef(null);
  const encoderRef = useRef(null);
  const mediaStreamSourceRef = useRef(null); // Ref to store the mediaStream
  const myAudioProcessorNodeRef = useRef(null);
  const desiredSampleRate = 16000;
  var sequenceNumber = 0;
  const nextAudioStartTimeRef = useRef(0);
  const buttonRef = useRef(null);
  const [volumeLevel, setVolumeLevel] = useState(0);
  const [startTime, setStartTime] = useState(null);
  const lastMessageRef = useRef("");
  const timeoutIdRef = useRef(null); //
  const intervalRef = useRef(null); // Ref to hold the interval ID
  const [intercomImage, setIntercomImage] = useState(IntercomBlack);

  const opened = useRef(false);

  const {
    connect,
    sendMessage,
    addListener,
    removeListener,
    isConnected,
    close,
  } = useWebSocket(wsUrl);

  useEffect(() => {
    if (!loaded) {
      connectWS(info.ip);
      setButtonImage(Loading);
    }
  }, [loaded, info.ip]); // Dependency on 'loaded' and 'info.ip' to ensure effect runs only if not loaded

  useEffect(() => {
    const { intercom } = props.pinfo || {};

    // If requester is not null, start alternating between IntercomBlack and IntercomRed
    if (intercom?.requester) {
      intervalRef.current = setInterval(() => {
        setIntercomImage((prevImage) => {
          return prevImage === IntercomBlack ? IntercomRed : IntercomBlack;
        });
      }, 1000); // Alternate every second
    } else {
      setIntercomImage(IntercomBlack); // Reset to IntercomBlack
    }

    // Clean up the interval on unmount or when the requester is null
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
        intervalRef.current = null;
      }
    };
  }, [props.pinfo]);

  useEffect(() => {
    // Initialize the decoder and store it in the ref
    decoderRef.current = new Decoder({
      rate: 16000,
      channels: 1,
      //unsafe: true,
    });

    // No need to include decoder in the dependency array since it's now a ref
  }, []);

  useEffect(() => {
    //console.log("NurseCall", props.pinfo);
  }, [props.pinfo]);

  useEffect(() => {
    if (props.forceClose) {
      handleClosePopup();
    }
  }, [props.forceClose]);

  useEffect(() => {
    // Initialize the Opus encoder with specified properties
    encoderRef.current = new Encoder({
      rate: 48000, // Use the SAMPLE_RATE
      channels: 1, // CHANNEL_CONFIG as mono
    });

    console.log("init encoderRef");
    return () => {
      // Cleanup if necessary, e.g., encoderRef.current.delete() if the API supports it
    };
  }, []);

  useEffect(() => {
    const mac = info.bcu_wifi_mac.replaceAll(":", "");
    const patientNameContainer = $(`.patient-name.${mac}`).width();
    const patientNameSpan = $(`.patient-name.${mac} span`).width();
    const marginLeft = 20;
    if (patientNameSpan > patientNameContainer - marginLeft) {
      setState({ ...state, needMarquee: true });
    }
  }, [info.patient_name]);

  useEffect(() => {
    console.log("Component mounted.");
    return () => {
      // Clean up AudioContext when the component unmounts
      if (
        audioContextRef.current &&
        audioContextRef.current.state !== "closed"
      ) {
        console.log("Closing AudioContext...");
        audioContextRef.current.close();
      }
    };
  }, []);

  useEffect(() => {
    if (opened.current && !props.isIntercomOpen) {
      const endCallWaiting = CommandCreator.endCallWaiting();
      handleSend(endCallWaiting);
      opened.current = false;
    }
  }, [props.isIntercomOpen]);

  const [isModalOpen, setIsModalOpen] = useState(false); // State to control the modal visibility

  const handleClosePopup = () => {
    setIsModalOpen(false);
    const pressCommand = CommandCreator.endAudioSession();
    handleSend(pressCommand);
    props.handleClosePopup();
  };

  const playAudio = useCallback((audioData) => {
    //console.log("Play audio");
    const audioContext = audioContextRef.current;

    if (!audioContext || audioContext.state === "closed") {
      console.warn("AudioContext is not initialized or has been closed.");
      return;
    }

    const play = async () => {
      if (audioContext.state === "suspended") {
        try {
          await audioContext.resume();
          console.log("AudioContext resumed successfully.");
        } catch (error) {
          console.error("Error resuming AudioContext:", error);
          return;
        }
      }

      try {
        // Create an audio buffer and copy the audioData into it.
        const buffer = audioContext.createBuffer(
          1,
          audioData.length,
          audioContext.sampleRate
        );
        buffer.getChannelData(0).set(audioData);

        // Create a buffer source node and set the buffer to it.
        const source = audioContext.createBufferSource();
        source.buffer = buffer;

        // Connect the source to the audioContext's destination (speakers).
        source.connect(audioContext.destination);

        // If nextAudioStartTime is in the past (less than the current time), update it to the current time.
        if (nextAudioStartTimeRef.current < audioContext.currentTime) {
          nextAudioStartTimeRef.current = audioContext.currentTime;
        }

        source.start(nextAudioStartTimeRef.current);
        nextAudioStartTimeRef.current += buffer.duration;
      } catch (error) {
        console.error("Error playing audio:", error);
      }
    };

    // console.log("Playing audio");
    play();
  }, []);

  // Function to poll jitter buffer, decode, and play audio
  const processJitterBuffer = useCallback(() => {
    const packet = jitterBufferRef.current.getPacket();
    if (packet) {
      try {
        const audioData = decoderRef.current.decodeFloat32(
          Buffer.from(packet.data)
        );

        //console.log(audioData.buffer);
        playAudio(new Float32Array(audioData.buffer));
      } catch (error) {
        console.error("Error processing jitter buffer packet:", error);
      }
    }
  }, [playAudio]);

  const calculateVolume = (audioData) => {
    let sum = 0.0;
    for (let i = 0; i < audioData.length; i++) {
      sum += audioData[i] * audioData[i];
    }
    const rms = Math.sqrt(sum / audioData.length);
    //console.log(rms);
    const volume = Math.min(Math.max(rms, 0), 1); // Clamp between 0 and 1
    const scaledVolume = Math.round(volume * 500); // Scale to range 1 to 10
    return scaledVolume;
  };

  const onAudioData = useCallback((message) => {
    //console.log("onAudioData " + autoFlow);
    const audioPacket = parseMessageInBytes(message);
    // Add to jitter buffer instead of direct playback
    const packet = new AudioPacket(
      audioPacket.data,
      audioPacket.sequenceNumber,
      audioPacket.flags
    );
    jitterBufferRef.current.addPacket(packet);
    // Calculate volume level
    const audioData = decoderRef.current.decodeFloat32(
      Buffer.from(audioPacket.data)
    );
    const volume = calculateVolume(audioData);
    // console.log("volume : " + volume);
    setVolumeLevel(volume); // Update the volume level state
  }, []);

  const handleMessage = (event) => {
    if (!recordingRef.current) {
      return;
    }
    try {
      const encodedData = encoderRef.current.encode(
        new Float32Array(event.data.audioData) //.slice(0, 120)
      );
      //  console.log(encodedData);
      sequenceNumber = sequenceNumber == 255 ? 0 : ++sequenceNumber;
      const audioData = CommandCreator.clientAudioData(
        sequenceNumber,
        encodedData
      );
      handleSend(audioData);
      setVolumeLevel(0);
    } catch (e) {
      console.log(e);
    }
  };

  // Helper function to wait for lock release
  const waitForLockRelease = () => {
    return new Promise((resolve) => {
      const checkLock = () => {
        if (!lockRef.current) {
          resolve(); // Lock is released, continue execution
        } else {
          setTimeout(checkLock, 20); // Check again after 20ms
        }
      };
      checkLock(); // Start checking
    });
  };

  // Function to acquire the lock
  const acquireLock = async () => {
    if (!lockRef.current) {
      lockRef.current = true;

      // Clear any previous timeout in case a new lock is acquired before the old one times out
      if (lockTimeoutRef.current) {
        clearTimeout(lockTimeoutRef.current);
      }

      // Set a new timeout to release the lock after 1 second
      lockTimeoutRef.current = setTimeout(() => {
        lockRef.current = false; // Automatically release lock after 1 second
        lockTimeoutRef.current = null; // Clear the reference to the timeout
      }, 1000000);

      return true;
    }
    return false;
  };

  // Function to release the lock manually
  const releaseLock = () => {
    lockRef.current = false;

    // Clear the timeout when the lock is manually released
    if (lockTimeoutRef.current) {
      clearTimeout(lockTimeoutRef.current);
      lockTimeoutRef.current = null; // Clear the reference to the timeout
    }
  };

  const openIntercom = () => {
    console.log("........openIntercom");
    props.openIntercom();
    opened.current = true;
    const startCallWaiting = CommandCreator.startCallWaiting();
    handleSend(startCallWaiting);
  };

  useEffect(() => {
    let timeoutId = null; // To store the timeout ID

    document.title = "OAC Audio"; // Set the document title

    const listener = {
      connection: async (status) => {
        console.log(`Connection status: ${status}`);
        if (status === "connected") {
          const startSessionCommand = CommandCreator.startAudioSession();
          handleSend(startSessionCommand);

          console.log("Start Session Command Sent");
          // Set the timeout to call handleClosePopup after 10 seconds

          if (timeoutIdRef.current == null) {
            timeoutIdRef.current = setTimeout(() => {
              console.log("Session timed out, closing popup.");
              handleClosePopup();
            }, 10000); // 10 seconds
          }
        } else if (status === "disconnected") {
          setIsModalOpen(true);
        }
      },
      onReject: (client) => console.log(`Rejected: ${client}`),
      onStartSessionAck: () => {
        console.log("Start session acknowledged");
        setButtonImage(MicButtonOff);
        setLoaded(true);
        isSessionOpen.current = true;
        // Clear the timeout if it's still active
        if (timeoutIdRef.current) {
          clearTimeout(timeoutIdRef.current);
          timeoutIdRef.current = null; // Reset the ref to null
        }
      },
      onEndSessionAck: () => console.log("End session acknowledged"),
      onStartAck: async () => {
        while (lockRef.current) {
          console.log(
            "--------------------------------------------------------- onStartAck locking..."
          );
          await waitForLockRelease(); // Wait for the lock to be released
        }
        console.log("onStartAck no lock");
        const isLockAcquired = await acquireLock(); // Try to acquire the lock

        console.log("AULOG: 1onStartAck");

        // console.log("AULOG: 1onStartAck" + " " + new Date().getTime());
        await new Promise((resolve) => setTimeout(resolve, 300));
        try {
          const audioContext = new (window.AudioContext ||
            window.webkitAudioContext)();
          audioContextRef.current = audioContext;

          if (audioContext.state === "suspended") {
            await audioContext.resume();
          }

          // console.log("process.env.PUBLIC_URL");
          await audioContext.audioWorklet.addModule(
            `${process.env.PUBLIC_URL}/my-audio-processor.js`
          );

          const mediaStream = await navigator.mediaDevices.getUserMedia({
            audio: true,
            video: false,
          });
          mediaStreamSourceRef.current =
            audioContext.createMediaStreamSource(mediaStream);
          // Store the AudioWorkletNode in myAudioProcessorNodeRef
          myAudioProcessorNodeRef.current = new AudioWorkletNode(
            audioContext,
            "my-audio-processor",
            {
              processorOptions: {
                sampleRate: 16000,
              },
            }
          );
          mediaStreamSourceRef.current.connect(myAudioProcessorNodeRef.current);
          myAudioProcessorNodeRef.current.connect(audioContext.destination);
          myAudioProcessorNodeRef.current.port.onmessage = handleMessage;
        } catch (error) {
          console.error(error);
          console.log(error.name);
        }
        console.log("AULOG: 2onStartAck" + " " + new Date().getTime());
        releaseLock();
      },
      onEndAck: async () => {
        while (lockRef.current) {
          console.log(
            "--------------------------------------------------------- onEndAck locking..."
          );
          await waitForLockRelease(); // Wait for the lock to be released
        }
        console.log("onEndAck no lock");
        const isLockAcquired = await acquireLock(); // Try to acquire the lock

        console.log("AULOG: 1onEndAck" + " " + new Date().getTime());
        try {
          setButtonImage(MicButtonOff);
          recordingRef.current = false;
          if (myAudioProcessorNodeRef.current) {
            myAudioProcessorNodeRef.current.port.removeEventListener(
              "message",
              handleMessage
            );
            myAudioProcessorNodeRef.current.disconnect();
            myAudioProcessorNodeRef.current = null;
          }
          if (
            mediaStreamSourceRef.current &&
            mediaStreamSourceRef.current.mediaStream
          ) {
            const tracks = mediaStreamSourceRef.current.mediaStream.getTracks();
            tracks.forEach((track) => track.stop());
            mediaStreamSourceRef.current.disconnect();
            mediaStreamSourceRef.current = null;
          }

          nextAudioStartTimeRef.current = 0;
          audioContextRef.current = new AudioContext({
            sampleRate: desiredSampleRate,
          });
        } catch (error) {
          console.error(error);
          console.log(error.name);
        }
        console.log("AULOG: 2onEndAck" + " " + new Date().getTime());
        releaseLock();
      },
      onAudioData,
    };

    addListener(listener);

    // Automatically attempt to connect when the component mounts or the wsUrl changes
    //connect();

    // Clean up by removing the listener when the component unmounts
    return () => {
      removeListener(listener);
    };
  }, [connect, addListener, removeListener, wsUrl, isConnected]);

  function reverseFloat32Array(array) {
    const reversedArray = new Float32Array(array.length);
    for (let i = 0, j = array.length - 1; i < array.length; i++, j--) {
      reversedArray[i] = array[j];
    }
    return reversedArray;
  }

  useEffect(() => {
    const process = () => {
      processJitterBuffer();
    };

    // Start polling jitter buffer when the component mounts
    pollIntervalRef.current = setInterval(process, 1); // Poll every 20ms

    return () => {
      // Clear interval when the component unmounts
      console.log("Clearing interval for jitter buffer...");
      if (pollIntervalRef.current) {
        clearInterval(pollIntervalRef.current);
      }
    };
  }, [processJitterBuffer]); // Ensure processJitterBuffer is listed as a dependency

  const connectWS = (ip) => {
    if (isConnected.current) {
      close(); // Call close method if already connected
      isSessionOpen.current = false; // Ensure session state is reset when disconnecting
      //setIsRecording(false); // Optionally reset recording state as well
      recordingRef.current = false;
    } else {
      console.log(`Connecting to IP: ${ip}, Port: ${port}`);
      connect(); // Call connect method if not connected
      // Initialize or resume AudioContext on user interaction
      // Lazy initialization of AudioContext to ensure it's created upon user action
      if (
        !audioContextRef.current ||
        audioContextRef.current.state === "closed"
      ) {
        console.log("Initializing AudioContext...");
        audioContextRef.current = new AudioContext({
          sampleRate: desiredSampleRate,
        });
      } else if (audioContextRef.current.state === "suspended") {
        console.log("Resuming AudioContext...");
        audioContextRef.current.resume();
      }
    }
  };

  // const handleStartEndSession = () => {
  //   if (isSessionOpen) {
  //     // Send End Session Command
  //     const endSessionCommand = CommandCreator.endAudioSession();
  //     handleSend(endSessionCommand);
  //     setIsSessionOpen(false);
  //     console.log("End Session Command Sent");
  //     // Implement retry logic as needed
  //   } else {
  //     // Send Start Session Command
  //     // const startSessionCommand = CommandCreator.startAudioSession();
  //     // handleSend(startSessionCommand);
  //     // setIsSessionOpen(true);
  //     // console.log("Start Session Command Sent");
  //     // Implement retry logic as needed
  //   }
  // };

  const handleSend = (msg) => {
    // console.log("isConnected: " + isConnected);
    sendMessage(msg);
  };

  const startRecording = async () => {
    if (!isSessionOpen.current) {
      console.log("blocked");
      return;
    }
    if (recordingRef.current) {
      return;
    }
    recordingRef.current = true;
    //setIsRecording(true);
    setButtonImage(MicButton); //if (autoFlow == -1)
    setStartTime(Date.now());
    //setIsStopping(false);
    const pressCommand = CommandCreator.buttonPressedCommand();
    if (lastMessageRef.current != pressCommand) {
      lastMessageRef.current = pressCommand;
      handleSend(pressCommand);
    }

    // Setup global event listeners for stopping the recording
    // window.addEventListener("mouseup", stopRecording);
    // window.addEventListener("touchend", stopRecording);

    //setIsRecording(true);
  };

  const stopRecording = () => {
    if (!isSessionOpen.current) {
      console.log("blocked");
      return;
    }
    if (!recordingRef.current) {
      return;
    }
    //setIsStopping(true); // Mark as stopping to prevent multiple calls

    const elapsedTime = Date.now() - startTime; // Calculate elapsed time

    const executeStop = () => {
      const releaseCommand = CommandCreator.buttonReleasedCommand();
      if (lastMessageRef.current != releaseCommand) {
        lastMessageRef.current = releaseCommand;
        handleSend(releaseCommand);
      }
    };

    executeStop();
  };

  const SoundBar = ({ volume }) => {
    const bars = Array.from({ length: 10 }, (_, i) => {
      let backgroundColor;

      if (i >= volume) {
        backgroundColor = "grey";
      } else if (i === 9) {
        backgroundColor = "red";
      } else if (i >= 7) {
        backgroundColor = "yellow";
      } else {
        backgroundColor = "green";
      }

      return (
        <div
          key={i}
          style={{
            height: "8%",
            width: "100%",
            background: backgroundColor,
            transition: "background 0.1s ease-in-out",
            marginTop: "4px", // Add margin between bars except the first one
          }}
        />
      );
    });

    return (
      <div
        style={{
          height: "100%", // Full height of the parent container
          width: "40px", // Adjusted width for the vertical bar
          display: "flex",
          flexDirection: "column-reverse", // Start from the bottom
          justifyContent: "flex-end",
        }}
      >
        {bars}
      </div>
    );
  };
  return (
    <div
      className={cx(
        "nurse-call",
        info.audibleActionError && info.hasSound && info.enableAll
          ? "sound-icon-trigger"
          : ""
      )}
    >
      <div className="patient-container">
        <div
          className={cx("patient-name", info.bcu_wifi_mac.replaceAll(":", ""))}
        >
          {state.needMarquee ? (
            <Marquee>
              <span>{info.patient_name}</span>
              <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
            </Marquee>
          ) : (
            <span>{info.patient_name}</span>
          )}
        </div>

        <div className="align-right">
          <div
            className="select-intercom"
            onClick={openIntercom} // Open Intercom on button click
          >
            <img src={intercomImage} />
          </div>
          <button
            onClick={handleClosePopup}
            style={{
              padding: "0 8px",
              fontSize: "16px",
              border: "none",
              background: "transparent",
              cursor: "pointer",
              color: "inherit", // Inherits text color from the parent or can be set to a specific color
            }}
          >
            <img src={CloseButton} />
          </button>
        </div>
      </div>

      <div className="status font-weight-bold">
        <div className="status-resp">
          <div className="status-img-resp">
            <img src={BreathImg} />
          </div>
          <div
            className={cx(
              "status-value-resp",
              info.isExceedBr || info.status?.status === 15 ? "color-red" : "",
              info.status?.status === 1 ||
                //info.status?.nurseCall === 1 ||
                info.status?.status === 4
                ? // || info.status?.status === 5
                  "blinking"
                : ""
            )}
          >
            {formatReading(info.status?.br, info.status?.status)}
          </div>
        </div>
        <div className="status-pr">
          <div className="status-img-pr">
            <img src={HeartImg} />
          </div>
          <div
            className={cx(
              "status-value-pr",
              info.isExceedHr || info.status?.status === 15 ? "color-red" : "",
              info.status?.status === 1 ||
                // || info.status?.status === 3
                info.status?.status === 4
                ? // || info.status?.status === 5
                  "blinking"
                : ""
            )}
          >
            {formatReading(info.status?.hr, info.status?.status)}
          </div>
        </div>
      </div>

      <div
        className={cx(
          "icons",
          info.status?.status === 0 ? "border-sleeping" : "",
          info.status?.status === 1 ? "border-sleeping" : "",
          info.status?.status === 2 ? "border-absent" : "",
          info.status?.status === 3 ? "border-awake" : "",
          info.status?.status === 4 ? "border-sleeping" : "",
          info.status?.status === 5 ? "border-sleeping" : "",
          info.status?.status === 15 ? "border-eol" : "",
          info.status?.status === 16 ? "border-sleeping" : "",
          info.status?.hr === 254 ? "bed-e1" : ""
        )}
      >
        <div className="layout-wrapper">
          <div className="icons-wrapper-A">
            {formatStatusImg(
              info.status?.pos,
              info.status?.status,
              info.audibleActionError && info.hasSound && info.enableAll,
              info.isAwake,
              info.status?.nurseCall,
              props,
              info
            )}
          </div>
          <div className="icons-wrapper-B">
            <SoundBar volume={volumeLevel} className="volume-bar" />
            <img
              src={volumeLevel > 0 ? greenSpeaker : greySpeaker}
              alt="Speaker Icon"
              className="speaker-icon"
            />
          </div>
        </div>
        <div className={cx("bed-name", `n-beds-${props.ntiles}`)}>
          <span>{info.bed_name}</span>
        </div>

        <button className="mic-button">
          <img
            src={buttonImage}
            alt="Button Icon"
            draggable="false" // Prevents the image from being draggable
            onMouseDown={startRecording}
            onMouseUp={stopRecording}
            onMouseLeave={stopRecording} // Handle the case where the mouse leaves the button while pressed
            onTouchStart={startRecording} // For touch devices
            onTouchEnd={stopRecording}
          />
          <span>Tap and hold to talk</span>
        </button>
      </div>

      {isModalOpen && (
        <>
          <div className="iresp-modal-overlay" onClick={handleClosePopup}></div>
          <div className="iresp-modal-container">
            <IrespModal
              clazz="duplicate-login-modal"
              title={t("Audio_error_title")}
              contents={[
                <div key="content-1a">
                  <div>
                    {t("Audio_error_resident")}: {info.patient_name}
                  </div>
                  <div>{t("Audio_error_description")}</div>
                </div>,
              ]}
              buttons={[<button onClick={handleClosePopup}>{"OK"}</button>]}
            />
          </div>
        </>
      )}
    </div>
  );
}

function formatReading(value, status) {
  // error first
  if (value === 254) {
    return <span className="error">E1</span>;
  } else if (value === 251) {
    return <span className="error">E8</span>;
  }

  // exceed threshold
  if (
    value === undefined ||
    value === 240 ||
    value === 255 ||
    status === 5 ||
    status === 3 ||
    status === 2
  ) {
    return <span className="spash-big font-weight-normal">--</span>;
  }

  return value;
}

// patient sitting is not used
function formatStatusImg(pos, status, isRed, isAwake, nurseCall, props, info) {
  // error first
  const errorImg = getStatusError(pos, status);

  if (errorImg !== "") {
    return errorImg;
  }

  // data after
  switch (pos) {
    case 0:
      return (
        <div className="pos-status-group">
          {/*<img src={Awake1}/>;*/}
          {getStatusImg(status, isAwake)}
        </div>
      );
    case 1:
      return (
        <div className="pos-status-group">{getStatusImg(status, isAwake)}</div>
      );
    case 2:
      return (
        <div className="pos-status-group">
          <img src={AbsentImg} />
        </div>
      );
    default:
  }
}

function getStatusError(pos, status) {
  if (pos === 254 || status === 254) {
    return undefined;
  } else if (pos === 251 || status === 251) {
    return undefined;
  } else if (pos === 240 || status === 240) {
    return undefined;
    // return <img src={SpinnerImg} className="img-error tile-spinner-2"/>;
  }

  return "";
}

function getStatusImg(status, isAwake) {
  switch (status) {
    case 0:
      return <img src={SleepingImg} />;
    case 1:
      return <img className="img-movement fade-opacity" src={SleepingImg} />;
    case 2:
      return <img src={AbsentImg} />;
    case 3:
      return <img src={AwakeImg} />;
    case 4:
      return <img className="img-tts fade-opacity" src={SleepingImg} />;
    case 5:
      return <img className="img-judgement fade-opacity" src={SleepingImg} />;
    case 15:
      return <img src={EOLImg} />;
    case 16:
      return <img src={SleepingImg} />;
  }
}
