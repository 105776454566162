import React, { useState, useEffect, useRef } from "react";
import AgoraRTC from "agora-rtc-sdk-ng";
import "./Intercom.css";
import MicDisableImage from "../../../images/mic2_disable.png";
import MicEnableImage from "../../../images/mic2_enable.png";
import MicMute from "../../../images/mic2_disable.png";
import Speaking from "../../../images/Volumn-Max.png";
import CloseButton from "../../../images/oac/OAC_Plain_SVG_5.png";
import { IrespModal } from "../../common/IrespModal";
import { messageWrapper as t } from "../../../utils/message-wrapper";
import Loading from "../../../images/oac/icon_loading_hq.gif";

//const apiUrl = "http://172.18.20.124:8088";
const apiUrl = "https://api.oac-system.com:8443";

export function Intercom(props) {
  const { onClose, token, channelName, uid } = props;
  const [loading, setLoading] = useState(true);
  const [mute, setMute] = useState(true);
  const [uidJoinedList, setUidJoinedList] = useState([]);
  const [volumeTable, setVolumeTable] = useState({});
  const [roomId, setRoomId] = useState("");
  const [buttonImage, setButtonImage] = useState(MicEnableImage);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const intervalRef = useRef(null);
  const connectedBefore = useRef(null);

  const rtcEngineRef = useRef(null);
  const localAudioTrackRef = useRef(null);

  const appId = "535654ea79a7400e8efb644190241890";

  useEffect(() => {
    console.log("props.createRoom  :" + props.createRoom);
    const token = sessionStorage.getItem(
      `${sessionStorage.getItem("OMNI2_ROLE")}_token`
    );
    joinRoom(token);
  }, []);

  // useEffect(() => {
  //   // console.log("Updated volumeTable:", volumeTable);
  // }, [volumeTable]);

  // useEffect(() => {
  //   // console.log("Nurses Information", props.nurses);
  // }, [props.nurses]);

  useEffect(() => {
    console.log("propsinfo", props.info.intercom);

    const currentTime = Date.now();

    // Check if connectedBefore has a value and if more than 10 seconds have passed
    if (
      connectedBefore.current &&
      currentTime - connectedBefore.current > 10000 &&
      props.info?.intercom?.requester == null
    ) {
      handleClose(); // Close if the conditions are met
    }
  }, [props.info]); // This effect runs when props.info changes

  useEffect(() => {
    console.log("forceClose :" + props.forceClose);
    if (props.forceClose) {
      handleClose();
    }
  }, [props.forceClose]);

  const extractIdFromNurseId = (nurseId) => {
    try {
      if (nurseId.includes(".")) {
        const parts = nurseId.split(".");
        const lastPart = parts[parts.length - 1];
        const parsedId = parseInt(lastPart, 10);

        if (isNaN(parsedId)) {
          throw new Error("Invalid number format");
        }

        return parsedId;
      } else {
        return 1000; // Default value if no dot found
      }
    } catch (error) {
      return 1000; // Fallback to default value in case of an exception
    }
  };

  const getNurseName = (uid) => {
    const nurse = props.nurses.find(
      (n) => extractIdFromNurseId(n.id) === parseInt(uid, 10)
    );
    return nurse ? nurse.name : `User ${uid}`;
  };

  const handleClosePopup = () => {
    setIsModalOpen(false);

    handleClose();
  };

  const initializeAgoraEngine = async (token, channelName, uid, requester) => {
    try {
      rtcEngineRef.current = AgoraRTC.createClient({
        mode: "live",
        codec: "vp8",
      });

      rtcEngineRef.current.on("user-joined", (user) => {
        console.log("propsinfo  user-joined: " + user.uid);
        setLoading(false);
        if (intervalRef.current != null) {
          console.log("propsinfo in");
          clearInterval(intervalRef.current);

          connectedBefore.current = Date.now();
          intervalRef.current = null;
        }
        console.log("propsinfo done");
        setUidJoinedList((prevList) => [...prevList, user.uid]);
      });

      rtcEngineRef.current.on("user-left", (user) => {
        console.log("user-left ", user.uid);
        setUidJoinedList((prevList) => prevList.filter((u) => u !== user.uid));
        console.log(
          "extracted props.info?.intercom?.requester : " +
            props.info?.intercom?.requester
        );
        let extracted = extractIdFromNurseId(props.info?.intercom?.requester);
        console.log("extracted : " + extracted);
        console.log("extracted user : " + user.uid);
        if (extracted === user.uid) {
          console.log("Extracted and user.uid are equal.");
          handleClose();
        } else {
          console.log("Extracted and user.uid are not equal.");
          console.log(`Type of extracted: ${typeof extracted}`);
          console.log(`Type of user.uid: ${typeof user.uid}`);
        }
      });

      rtcEngineRef.current.enableAudioVolumeIndicator(200);

      rtcEngineRef.current.on("volume-indicator", (volumes) => {
        volumes.forEach((volume) => {
          setVolumeTable((prevTable) => ({
            ...prevTable,
            [volume.uid]:
              prevTable[volume.uid] === -1 && volume.level === 0
                ? -1
                : volume.level,
          }));
        });
      });
      rtcEngineRef.current.on("user-info-updated", (uid, userInfo) => {
        setVolumeTable((prevTable) => {
          let updatedVolumeTable = { ...prevTable };

          if (userInfo === "mute-audio") {
            updatedVolumeTable[uid] = -1;
          } else if (userInfo === "unmute-audio") {
            updatedVolumeTable[uid] = 0;
          }

          return updatedVolumeTable;
        });
      });

      await rtcEngineRef.current.setClientRole("host");

      rtcEngineRef.current.on("user-published", async (user, mediaType) => {
        await rtcEngineRef.current.subscribe(user, mediaType);

        if (mediaType === "audio") {
          const remoteAudioTrack = user.audioTrack;
          if (remoteAudioTrack) {
            remoteAudioTrack.play();
          }
        }
      });

      await rtcEngineRef.current.join(appId, channelName, token, uid);

      localAudioTrackRef.current = await AgoraRTC.createMicrophoneAudioTrack();
      await rtcEngineRef.current.publish([localAudioTrackRef.current]);

      if (requester) {
        setMute(false);
        setButtonImage(MicEnableImage);
        intervalRef.current = setInterval(() => {
          if (uidJoinedList.length == 0) {
            setIsModalOpen(true);
          }
        }, 15000); // Alternate every second
      } else {
        setMute(true);
        setButtonImage(MicEnableImage);
        if (localAudioTrackRef.current) {
          localAudioTrackRef.current.setMuted(true);
        }
      }
    } catch (error) {
      console.error("Agora SDK initialization error:", error);
    }
  };

  const joinRoom = async (token) => {
    try {
      const response = await fetch(`${apiUrl}/api/data/join_room`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify({
          createRoom: props.createRoom,
        }),
      });

      const data = await response.json();
      if (response.ok && data) {
        setRoomId(data.room_id || "");
        initializeAgoraEngine(data.token, data.channel, 1000, data.requester);
      } else {
        console.log("Failed to join room");
      }
    } catch (error) {
      console.error("Error joining room:", error);
    }
  };

  const closeRoom = async (token) => {
    try {
      const response = await fetch(`${apiUrl}/api/data/close_room`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify({}),
      });

      if (response.ok) {
        // if (rtcEngineRef.current) {
        //   rtcEngineRef.current.leave();
        // }
        console.log("Room closed successfully");
      } else {
        console.log("Failed to close room");
      }
    } catch (error) {
      console.error("Error closing room:", error);
    }
  };

  const toggleMute = () => {
    setMute((prevMute) => {
      const newMute = !prevMute;
      if (localAudioTrackRef.current) {
        localAudioTrackRef.current.setMuted(newMute);
      }
      return newMute;
    });
  };

  useEffect(() => {
    return () => {
      if (rtcEngineRef.current) {
        rtcEngineRef.current.leave();
        rtcEngineRef.current = null;
      }
      if (localAudioTrackRef.current) {
        localAudioTrackRef.current.close();
        localAudioTrackRef.current = null;
      }
    };
  }, []);

  useEffect(() => {
    if (token && channelName && uid) {
      initializeAgoraEngine(token, channelName, uid);
    }
  }, [token, channelName, uid]);

  const handleClose = () => {
    if (roomId) {
      localStorage.setItem("savedRoomId", roomId); // Save the room_id to localStorage
    }
    const token = sessionStorage.getItem(
      `${sessionStorage.getItem("OMNI2_ROLE")}_token`
    );
    clearInterval(intervalRef.current);
    closeRoom(token);
    onClose(roomId);
  };

  const isNurseOnline = (nurseId) => {
    return uidJoinedList.some((user) => parseInt(user, 10) === nurseId);
  };

  return (
    <div className="intercom-container">
      <div className="intercom">
        {/* Container with relative positioning for loading overlay */}
        <div className="dialog-content" style={{ position: "relative" }}>
          {/* Conditionally render the loading overlay */}
          {loading && (
            <div className="loading-overlay">
              <img src={Loading} alt="Loading..." className="loading-spinner" />
            </div>
          )}

          {/* Actual content below the overlay */}
          <div className="patient-container">
            <div className="patient-name">
              <span>Nurse Admin</span>
            </div>

            <div>
              {volumeTable[1000] > 60 && (
                <img className="nurse-speaker" src={Speaking} alt="Speaking" />
              )}
              <button onClick={handleClose} className="close-button">
                <img src={CloseButton} alt="Close" />
              </button>
            </div>
          </div>

          <div className="dialog-body">
            <div className="user-list">
              <ul className="user-grid">
                {props.nurses.map((nurse) => {
                  const nurseId = extractIdFromNurseId(nurse.id);
                  const userStatus = isNurseOnline(nurseId)
                    ? "online"
                    : "offline";

                  return (
                    <li key={nurseId} className={`user-item ${userStatus}`}>
                      <span>{nurse.name}</span>
                      {userStatus === "online" && volumeTable[nurseId] > 10 && (
                        <img
                          className="nurse-item-image"
                          src={Speaking}
                          alt="Speaking"
                        />
                      )}
                      {userStatus === "online" &&
                        volumeTable[nurseId] <= 10 &&
                        volumeTable[nurseId] > 0 && (
                          <img
                            className="nurse-item-image"
                            src={Speaking}
                            alt="Speaking"
                          />
                        )}
                      {userStatus === "online" &&
                        volumeTable[nurseId] === -1 && (
                          <img
                            className="nurse-item-image"
                            src={MicMute}
                            alt="Muted"
                          />
                        )}
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
          <div className="divider"></div>
          <div className="dialog-footer">
            <button onClick={toggleMute} className="mute-button">
              <img
                src={mute ? MicDisableImage : MicEnableImage}
                alt={mute ? "Unmute" : "Mute"}
              />
            </button>
          </div>
        </div>
      </div>
      {isModalOpen && (
        <>
          <div onClick={handleClosePopup}></div>
          <div className="iresp-modal-container">
            <IrespModal
              clazz="intercompopup"
              title={t("no_device_title")}
              contents={[
                <div key="content-1a">
                  <div>{t("no_device")}</div>
                </div>,
              ]}
              buttons={[<button onClick={handleClosePopup}>{"OK"}</button>]}
            />
          </div>
        </>
      )}
    </div>
  );
}
